import { useEffect } from 'react';

function useColorSchemeListener(callback) {
  useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e) => {
      callback(e.matches ? 'dark' : 'light');
    };

    darkModeQuery.addEventListener('change', handleChange);
    
    // Call the callback initially to set up the correct color scheme
    handleChange(darkModeQuery);

    // Clean up event listener
    return () => {
      darkModeQuery.removeEventListener('change', handleChange);
    };
  }, [callback]);
}

export default useColorSchemeListener;