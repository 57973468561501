import React, { useState, useEffect } from 'react';
import { List, ListItem, BlockTitle } from 'konsta/react';
import axios from 'axios';

function Medium() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@marclaberge');
        if (response.data && response.data.items) {
          const formattedItems = response.data.items.map((item) => {
            const dateObject = new Date(item.pubDate);
            const formattedDate = dateObject.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
            return {
              ...item,
              formattedDate: formattedDate // Add formatted date to the item
            };
          });
          setItems(formattedItems);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchItems();
  }, []);  

  return (
    <>
      {items && items.length > 0 && (
        <div>
          <BlockTitle id='articles' large={true}>Articles</BlockTitle>
          <List strongIos outlineIos>
            {items.map((item, index) => (
              <ListItem
                key={index} // Using the index as the key, assuming it's unique
                link
                chevronMaterial={false}
                title={item.title}
                subtitle={item.formattedDate}
                href={item.link}
                target="_blank"
              />
            ))}
          </List>
        </div>
      )}
    </>
  );  
}

export default Medium;
