import React, { useState, useEffect } from 'react';
import { App, List, ListItem, Block, BlockTitle, Chip, Notification, Card, Link, Button,Sheet, Toolbar } from 'konsta/react';
import useColorSchemeListener from './useColorSchemeListener.js';
import Medium from './Medium.js';
import { useTranslation } from "react-i18next";
// import apple_spaceship from './assets/images/apple_spaceship.PNG'
import Carousel from './Carousel.jsx';

function Website() {
  const { t } = useTranslation();
  useColorSchemeListener((colorScheme) => {
    document.documentElement.classList.toggle('dark', colorScheme === 'dark');
    document.documentElement.classList.toggle('dark', colorScheme === 'dark');
    document.documentElement.style.backgroundColor = colorScheme === 'light' ? "#efeff4" : "#000";
    document.body.style.backgroundColor = colorScheme === 'light' ? "#efeff4" : "#000";

  });
  const [notificationFull, setNotificationFull] = useState(false);
  const [notificationWithButton, setNotificationWithButton] = useState(false);

  const openNotification = (setter) => {
    setNotificationFull(false);
    setNotificationWithButton(false);
    setter(true);
  };

  const [sheets, setSheets] = useState({});
  const [toolbarTitle, setToolbarTitle] = useState('');

  const handleLoadSheet = (src) => {
    setSheets((prevSheets) => ({
      ...prevSheets,
      [src]: false,
    }));
  };

  const openSheetWithLink = (link, title) => {
    setSheets((prevSheets) => ({
      ...prevSheets,
      [link]: true,
    }));
    setToolbarTitle(title);
  };

  const handleOnSheetDismiss = () => {
    setSheets({})
  };

  useEffect(() => {
    let timer;
    if (notificationFull) {
      timer = setTimeout(() => {
        setNotificationFull(false);
      }, 3000);
    }
    openNotification(setNotificationWithButton)
    return () => clearTimeout(timer);
  }, [notificationFull]);

  return (
    <App theme="ios" className='lg:max-w-5xl mx-auto custom-scrollbar'>
      <Notification
        opened={notificationWithButton}
        title={t("notification.title")}
        subtitle={t("notification.subtitle")}
        text={``}
        style={{opacity: 0.99}}
        className="fixed"
      />
      {/* <img src={apple_spaceship} alt="" className="spaceship flying"></img> */}

      <div className='h-24' />

      <BlockTitle id='socials' medium>{t("socials.title")}</BlockTitle>
      <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
        <Chip
            className="m-0.5"
            onClick={()=> window.open("https://www.linkedin.com/in/marc-antoine-laberge", "_blank")}
          >
            LinkedIn
        </Chip>
        <Chip
            className="m-0.5"
            onClick={()=> window.open("https://github.com/marcgeeklaberge", "_blank")}
          >
            GitHub
        </Chip>
        <Chip
            className="m-0.5"
            onClick={()=> window.open("https://www.instagram.com/marclaberge.ml/", "_blank")}
          >
            Instagram
        </Chip>
        <Chip
            className="m-0.5"
            onClick={()=> window.open("https://www.youtube.com/MarcLabergeML", "_blank")}
          >
            YouTube
        </Chip>
        <Chip
            className="m-0.5"
            onClick={()=> window.open(" https://x.com/MarcLabergeML", "_blank")}
          >
            X
        </Chip>
        <Chip
            className="m-0.5"
            onClick={()=> window.open(" https://www.threads.net/@marclaberge.ml", "_blank")}
          >
            Threads
        </Chip>
      </Block>

      <BlockTitle id='work' medium>{t("work.title")}</BlockTitle>
        <List strongIos outlineIos>
          <ListItem
            title={t("work.ssense3.roleName")}
            after={t("work.ssense3.dates")}
            subtitle={t("work.ssense3.subtitle")}
          />
          <ListItem
            title={t("work.ssense2.roleName")}
            after={t("work.ssense2.dates")}
            subtitle={t("work.ssense2.subtitle")}
          />
          <ListItem
            title={t("work.ssense1.roleName")}
            after={t("work.ssense1.dates")}
            subtitle={t("work.ssense1.subtitle")}
          />
          <ListItem
            title={t("work.ssense0.roleName")}
            after={t("work.ssense0.dates")}
            subtitle={t("work.ssense0.subtitle")}
          />
          <ListItem
            title={t("work.desjardins.roleName")}
            after={t("work.desjardins.dates")}
            subtitle={t("work.desjardins.subtitle")}
          />
        </List>

        <BlockTitle id='education' medium>{t("education.title")}</BlockTitle>
        <List strongIos outlineIos>
          <ListItem
            title={t("education.gradeName")}
            after={t("education.dates")}
            subtitle={t("education.universityName")}
          />
        </List>

        <BlockTitle id='projects' medium>{t("projects.title")}</BlockTitle>
        <Block>
          <div className="sm:grid sm:grid-cols-2 md:grid md:grid-cols-3">
            <Card
              raisedIos
              footer={
                <>
                  <div className="flex justify-between material:hidden">
                    <Link onClick={()=> {
                      window.open(t("projects.mnezy.appStoreLink"), "_blank")
                      }}>
                        App Store
                    </Link>
                    <Link onClick={()=> {
                      window.open(t("projects.mnezy.websiteLink"), "_blank")
                      }}>
                        Link
                    </Link>
                  </div>
                  <div className="flex justify-start ios:hidden space-x-2 rtl:space-x-reverse">
                    <Button rounded inline>
                      App Store
                    </Button>
                    <Button rounded inline outline>
                      Link
                    </Button>
                  </div>
                </>
              }
            >
              <div
                className="ios:-mx-4 ios:-mt-4 h-48 p-4 flex items-end text-white ios:font-bold bg-cover bg-center material:rounded-xl mb-4 material:text-[22px]"
                style={{
                  backgroundImage:
                    'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1)),' + // Adding a line at the bottom
                    'url(https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/f7/e0/cb/f7e0cb62-54f6-6e32-4907-06e241ebccc1/AppIcon-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp)',
                }}
              >
              </div>
              <div className="mb-3">{t("projects.mnezy.title")}</div>
              <div className="text-gray-500 mb-3">{t("projects.mnezy.subtitle")}</div>
              <p>{t("projects.mnezy.description")}</p>
            </Card>
            <Card
              raisedIos
              footer={
                <>
                  <div className="flex justify-between material:hidden">
                    <Link onClick={()=> {
                      window.open(t("projects.ssense.appStoreLink"), "_blank")
                      }}>
                        App Store
                    </Link>
                    <Link onClick={()=> {
                      window.open(t("projects.ssense.websiteLink"), "_blank")
                      }}>
                        Link
                    </Link>
                  </div>
                  <div className="flex justify-start ios:hidden space-x-2 rtl:space-x-reverse">
                    <Button rounded inline>
                      App Store
                    </Button>
                    <Button rounded inline outline>
                      Link
                    </Button>
                  </div>
                </>
              }
            >
              <div
                className="ios:-mx-4 ios:-mt-4 h-48 p-4 flex items-end text-white ios:font-bold bg-cover bg-center material:rounded-xl mb-4 material:text-[22px]"
                style={{
                  backgroundImage:
                    'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1)),' + // Adding a line at the bottom
                    'url(https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/32/79/ac/3279ac4b-b158-f0b0-aacd-5bef67b3d00d/AppIcon-1x_U007emarketing-0-5-0-85-220-0.png/460x0w.webp)',
                }}
              >
              </div>
              <div className="mb-3">{t("projects.ssense.title")}</div>
              <div className="text-gray-500 mb-3">{t("projects.ssense.subtitle")}</div>
              <p>{t("projects.ssense.description")}</p>
            </Card>
            <Card
              raisedIos
              footer={
                <>
                  <div className="flex justify-between material:hidden">
                    <Link onClick={()=> {
                      window.open(t("projects.desjardins.appStoreLink"), "_blank")
                      }}>
                        App Store
                    </Link>
                    <Link onClick={()=> {
                      window.open(t("projects.desjardins.websiteLink"), "_blank")
                      }}>
                        Link
                    </Link>
                  </div>
                  <div className="flex justify-start ios:hidden space-x-2 rtl:space-x-reverse">
                    <Button rounded inline>
                      App Store
                    </Button>
                    <Button rounded inline outline>
                      Link
                    </Button>
                  </div>
                </>
              }
            >
              <div
                className="ios:-mx-4 ios:-mt-4 h-48 p-4 flex items-end text-white ios:font-bold bg-cover bg-center material:rounded-xl mb-4 material:text-[22px]"
                style={{
                  backgroundImage:
                    'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1)),' + // Adding a line at the bottom
                    'url(https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/ca/a4/9c/caa49ca5-b2f6-4e15-6c5f-620260a10eee/AppIconDesjardins-1x_U007emarketing-0-4-0-85-220-0.jpeg/460x0w.webp)',
                }}
              >
              </div>
              <div className="mb-3">{t("projects.desjardins.title")}</div>
              <div className="text-gray-500 mb-3">{t("projects.desjardins.subtitle")}</div>
              <p>{t("projects.desjardins.description")}</p>
            </Card>
          </div>
        </Block>

        <BlockTitle id='languages'>{t("languages.title")}</BlockTitle>
        <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
          <Chip className="m-0.5">Swift</Chip>
          <Chip className="m-0.5">TypeScript</Chip>
          <Chip className="m-0.5">JavaScript</Chip>
          <Chip className="m-0.5">Kotlin</Chip>
        </Block>

        <BlockTitle id='frameworks'>{t("frameworks.title")}</BlockTitle>
        <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
          <Chip className="m-0.5">SwiftUI</Chip>
          <Chip className="m-0.5">UIKit</Chip>
          <Chip className="m-0.5">React Native</Chip>
          <Chip className="m-0.5">React</Chip>
        </Block>

        <BlockTitle id='platforms'>{t("platforms.title")}</BlockTitle>
        <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
          <Chip className="m-0.5">iOS</Chip>
          <Chip className="m-0.5">Android</Chip>
          <Chip className="m-0.5">iPadOS</Chip>
          <Chip className="m-0.5">watchOS</Chip>
          <Chip className="m-0.5">tvOS</Chip>
          <Chip className="m-0.5">visionOS</Chip>
        </Block>

        <BlockTitle id='sdks'>{t("SDKs.title")}</BlockTitle>
        <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
          <Chip className="m-0.5">Firebase</Chip>
          <Chip className="m-0.5">Stripe</Chip>
          <Chip className="m-0.5">Braze</Chip>
        </Block>

        <BlockTitle id='payments'>{t("paymentMethods.title")}</BlockTitle>
        <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
          <Chip className="m-0.5">Apple Pay</Chip>
          <Chip className="m-0.5">Klarna</Chip>
          <Chip className="m-0.5">PayPal</Chip>
          <Chip className="m-0.5">Credit</Chip>
        </Block>

        <BlockTitle id='others'>{t("others.title")}</BlockTitle>
        <Block className='overflow-x-scroll overflow-y-hidden whitespace-nowrap custom-scrollbar'>
          <Chip className="m-0.5">GraphQL</Chip>
          <Chip className="m-0.5">Lucidchart</Chip>
          <Chip className="m-0.5">GitHub</Chip>
          <Chip className="m-0.5">Figma</Chip>
        </Block>
        
        {/* <Carousel /> */}

        <Medium />

        <BlockTitle id='hobbies' large>{t('hobbies.title')}</BlockTitle>
        <List>
          <ListItem title={t('hobbies.list.0.title')} />
          <ListItem title={t('hobbies.list.1.title')} />
          <ListItem title={t('hobbies.list.2.title')} />
          <ListItem title={t('hobbies.list.3.title')} />
          <ListItem title={t('hobbies.list.4.title')} />
        </List>

        <BlockTitle id='sports' large>{t('sports.title')}</BlockTitle>
        <List strongIos outlineIos>
          <ListItem
            link
            chevronMaterial={false}
            title={t('sports.list.4.title')}
            subtitle={t('sports.list.4.subtitle')}
            // onClick={()=> window.open("https://redsox.marclaberge.org", "_blank")}
            onClick={() => openSheetWithLink("https://redsox.marclaberge.org", t('sports.list.4.title'))}
          />
          <ListItem
            // link
            chevronMaterial={false}
            title={t('sports.list.2.title')}
            subtitle={t('sports.list.2.subtitle')}
            // onClick={()=> window.open("https://celtics.marclaberge.org", "_blank")}
            // onClick={() => openSheetWithLink("https://celtics.marclaberge.org", t('sports.list.2.title'))}
          />
          <ListItem
            chevronMaterial={false}
            title={t('sports.list.3.title')}
            subtitle={t('sports.list.3.subtitle')}
          />
          <ListItem
            // link
            chevronMaterial={false}
            title={t('sports.list.0.title')}
            subtitle={t('sports.list.0.subtitle')}
            // onClick={()=> window.open("https://ch.marclaberge.org", "_blank")}
            // onClick={() => openSheetWithLink("https://ch.marclaberge.org", t('sports.list.0.title'))}
          />
          <ListItem
            // link
            chevronMaterial={false}
            title={t('sports.list.1.title')}
            subtitle={t('sports.list.1.subtitle')}
            // onClick={()=> window.open("https://rocket.marclaberge.org", "_blank")}
            // onClick={() => openSheetWithLink("https://rocket.marclaberge.org", t('sports.list.1.title'))}
          />
        </List>
        <Sheet
        className="pb-safe fixed w-full h-5/6 mx-auto"
        opened={Object.keys(sheets).length > 0}
        onBackdropClick={handleOnSheetDismiss}
        >
          <Toolbar top className={`transition-opacity duration-500 delay-200 ${Object.keys(sheets).length > 0 ? 'opacity-100 visible' : 'opacity-0'}`}>
            <div className="title">{toolbarTitle}</div>
            <div className="right">
              <Link toolbar onClick={handleOnSheetDismiss}>Done</Link>
            </div>
          </Toolbar>
          {Object.entries(sheets).map(([src, isLoading]) => (
            <div key={src} className={`"w-full h-full opacity-0 transition-opacity duration-700 ${isLoading ? '' : 'opacity-100'}`}>
              <iframe
                title="Selected Link"
                src={src}
                className="w-full h-full"
                onLoad={() => handleLoadSheet(src)}
              ></iframe>
            </div>
          ))}
        </Sheet>
    </App>
  );
}

export default Website;
