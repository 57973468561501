import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Website from './Website.js';
import "./i18n/config.ts";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Website />
);
